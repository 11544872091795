<template>
  <div class="page container">
    <div class="header-w">
      <div class="left">
        <van-icon name="apps-o" size="20" @click="onOpenVisitPopup"></van-icon>
        <span class="text">{{ folderName }}</span
        ><van-icon name="arrow" /><span class="text">{{ formName }}</span>
        <span style="margin-left: 10px">{{ subjid }}</span>
      </div>
      <div class="right">
        <img
          v-if="subjectReview == 1"
          style="width: 24px; height: 24px; margin-right: 10px"
          src="../assets/preview.png"
          @click="onReviewClick"
        />
        <img
          v-if="subjectSdv == 1"
          style="width: 24px; height: 24px; margin-right: 10px"
          src="../assets/wpexplorer.png"
          @click="onSdvClick"
        />
        <img
          v-if="subjectPhoto == 1"
          style="width: 24px; height: 24px"
          src="../assets/icon_camera.png"
          @click="onPhonoClick"
        />
        <img
          v-if="subjectPhoto == 0 && subjectAttachmentsView == 1"
          style="width: 24px; height: 24px"
          src="../assets/icon_photo_album.png"
          @click="onPhonoClick"
        />
      </div>
    </div>
    <!-- 侧边访视弹框 -->
    <van-popup
      v-model="showVisit"
      position="left"
      style="height: 100%; width: 60%"
      @close="onCloseVisitPopup"
    >
      <van-collapse v-model="visitActives" @change="onVisitCollapseChange">
        <div v-for="(item, index) in visitList" :key="item.folder_oid + index">
          <van-collapse-item
            :title="item.folder_name"
            :name="item.folder_oid + index"
          >
            <div v-for="childItem in item.forms" :key="childItem.folder_oid">
              <div
                class="visit-item"
                :class="handleFormClass(childItem)"
                @click="onSelectForm(item, childItem)"
              >
                <span class="visit-item-name"> {{ childItem.form_name }}</span>
                <img
                  v-if="childItem.is_lock"
                  class="lock-icon"
                  src="../assets/lock.png"
                />
                <img
                  v-if="childItem.is_freeze"
                  class="lock-icon"
                  src="../assets/freeze.png"
                />
                <img
                  src="../assets/save-fill.png"
                  class="lock-icon"
                  v-if="childItem.icon_status === 1"
                />
                <img
                  src="../assets/eyeslash.png"
                  class="lock-icon"
                  v-if="childItem.icon_status === 2"
                />
                <img
                  src="../assets/preview2.png"
                  class="lock-icon"
                  style="width: 12px; height: 12px"
                  v-if="childItem.is_review === 1"
                />
                <img
                  src="../assets/wpexplorer-o2.png"
                  class="lock-icon"
                  style="width: 14px; height: 14px"
                  v-if="childItem.is_sdv === 1"
                />
                <img
                  src="../assets/biaoqian2.png"
                  class="lock-icon"
                  style="width: 14px; height: 14px"
                  v-if="childItem.is_signature === 1"
                />
                <van-icon
                  size="13px"
                  v-if="childItem.icon_status === 3"
                  color="red"
                  name="question"
                />
                <van-icon
                  size="13px"
                  v-if="childItem.icon_status === 4"
                  color="#ff8917"
                  name="question"
                />
              </div>
            </div>
          </van-collapse-item>
        </div>
      </van-collapse>
    </van-popup>
    <van-grid
      v-if="form_info && form_info.is_log && !form_info.is_lab"
      :column-num="formListData.length > 2 ? 3 : formListData.length + 1"
    >
      <van-grid-item
        class="log-grid-item"
        v-for="(item, index) in formListData"
        @click="onGridItemClick(item, index)"
        :key="item.id"
      >
        <p :class="{ 'log-active': index === currentLogId }">
          {{ item.childs[0].title }}:{{ item.childs[0].value }}
        </p>
        <p :class="{ 'log-active': index === currentLogId }">
          {{ item.childs[1].title }}:{{ item.childs[1].value }}
        </p>
      </van-grid-item>
      <van-grid-item class="log-grid-item" v-if="showAddBtn">
        <button class="add-log-form" @click="onAddLogClick">新增表单</button>
      </van-grid-item>
    </van-grid>
    <van-grid
      v-if="form_info && form_info.is_log && form_info.is_lab"
      :column-num="formListData.length > 2 ? 3 : formListData.length + 1"
    >
      <van-grid-item
        class="log-grid-item"
        v-for="(item, index) in formListData"
        @click="onGridItemClick2(item, index)"
        :key="item.id"
      >
        <p :class="{ 'log-active': index === currentLogId }">
          {{ item.array[0].childs[0].title }}:{{ item.array[0].childs[0].value }}
        </p>
        <p :class="{ 'log-active': index === currentLogId }">
          {{ item.array[0].childs[1].title }}:{{ item.array[0].childs[1].value }}
        </p>
      </van-grid-item>
      <van-grid-item class="log-grid-item" v-if="showAddBtn">
        <button class="add-log-form" @click="onAddLogClick2">新增表单</button>
      </van-grid-item>
    </van-grid>
    <van-checkbox
      v-if="showCheckBox"
      class="check-all-box"
      v-model="checked"
      @click="onCheckAll"
      icon-size="18px"
    >
      <span class="check-text">全选</span>
    </van-checkbox>
    <div class="form-box" v-if="formList.length">
      <rh-form
        ref="rh-from-ref"
        v-for="(item, index) in formList"
        :key="item.id"
        :index="index"
        :formChild="item.childs"
        :isCheckAll="checked"
        :queryAdd="queryAdd"
        :canClearable="showAddBtn"
        @dataChange="onDataChange"
        @isCheckAllChange="onIsCheckAllChange"
        @notCheckedEvent="onNotCheckedEvent"
        @creatQueryClick="onCreatQueryClick"
        @queryClick="onQueryClick"
        class="form-one"
      >
      </rh-form>
    </div>
    <!-- form_info && !form_info.is_log &&  -->
    <footer v-if="showAddBtn" class="footer">
      <div class="btn-box" v-if="is_temp_data == 1">
        <van-button
          v-if="formList.length"
          class="submit-btn"
          size="small"
          plain
          type="info"
          @click="onSubmit(0)"
          style="flex: 1"
          >提 交</van-button
        >
        <van-button
          v-if="formList.length && isDisableTemporaryStorage != 1"
          class="submit-btn"
          size="small"
          plain
          type="primary"
          @click="onSubmit(1)"
          style="width: 150px; margin-left: 10px"
          >暂 存</van-button
        >
      </div>
      <div class="btn-box" v-else>
        <van-button
          v-if="formList.length"
          class="submit-btn"
          size="small"
          plain
          type="info"
          block
          @click="onSubmit(2)"
          >更 新</van-button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import {
  Icon,
  Popup,
  Collapse,
  CollapseItem,
  Checkbox,
  Notify,
  Toast,
  Button,
  Grid,
  GridItem,
} from "vant";
import service from "../js/service";
import qs from "qs";
import {
  handleEdcFormData,
  handleFormParams,
  handleFormClass,
  typeOf,
  log,
  common
} from "../js/utils/utils";
import wx from "weixin-js-sdk";
import rhForm from "../components/rh-form.vue";
import {
  enableFun,
  disableFun,
  hideFun,
  showFun,
  inArrayFun,
} from "../js/utils/functionLib";

export default {
  components: {
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Button.name]: Button,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    rhForm,
  },
  data() {
    return {
      folderName: "",
      formName: "",
      formInfo: {},
      showVisit: false,
      visitList: [],
      subject_id: "",
      projectId: "",
      currentParams: null,
      visitActives: [],
      handleFormClass: handleFormClass,
      formList: [],
      formListData: [],
      page_logic: "",
      formFunc: null,
      checked: false,
      envId: "",
      envName: "",
      roleName: "",
      roleId: "",
      allField: [],
      allTempArr: [],
      form_info: null,
      is_temp_data: 1,
      queryAdd: 0,
      subjectSdv: 0,
      subjectReview: 0,
      subjectEdit: 0,
      subjectAttachmentsView: 0,
      queryReply: 0,
      queryClose: 0,
      isDisableTemporaryStorage: 0,
      subjid: "",
      toast: null,
      notCheckedList: [],
      subjectPhoto: 0,
      logKey: "",
      currentLogId: 0,
      isLog: 0,
      logfields: [],
      isLab: 0,
      OCRURL: '',
      canOCR: true
    };
  },
  computed: {
    showCheckBox() {
      if (this.subjectReview == 1 || this.subjectSdv == 1) {
        if (this.formList.length) {
          let x = 0;
          this.formList.forEach((item) => {
            let childs = item && item.childs;
            childs &&
              childs.forEach((child) => {
                if (child.isReview || child.isSdv) {
                  x++;
                }
              });
          });
          return x > 0;
        } else {
          return false;
        }
      }
      return false;
    },
    showAddBtn() {
      return (
        this.subjectEdit == 1 &&
        this.formInfo.icon_status != "7" &&
        this.formInfo.icon_status != "6" &&
        this.formInfo.icon_status != "5" &&
        this.formInfo.icon_status != "2"
      );
    },
  },
  created() {
    
  },
  activated() {
    const {
      projectId,
      envId,
      roleName,
      roleId,
      envName,
      queryAdd,
      subjectSdv,
      subjectReview,
      subjectEdit,
      subjectPhoto,
      subjectAttachmentsView,
      queryClose,
      queryReply,
      subjid,
      isDisableTemporaryStorage,
    } = this.$route.query;
    this.subject_id = this.$route.query.subjectId;
    this.projectId = projectId || "";
    this.envId = envId || "";
    this.roleName = roleName || "";
    this.roleId = roleId || "";
    this.envName = envName || "";
    this.queryAdd = queryAdd || 0;
    this.subjectSdv = subjectSdv || 0;
    this.subjectReview = subjectReview || 0;
    this.subjectEdit = subjectEdit || 0;
    this.subjectPhoto = subjectPhoto || 0;
    this.subjectAttachmentsView = subjectAttachmentsView || 0;
    this.queryClose = queryClose || 0;
    this.queryReply = queryReply || 0;
    this.subjid = subjid || "";
    this.isDisableTemporaryStorage = isDisableTemporaryStorage || 0;
    this.GetVisitList();
  },
  methods: {
    async GetVisitList() {
      this.toast = Toast.loading("加载中...");
      const res = await service.sendReq(
        "get-subject-folder?subject_id=" + this.subject_id,
        null,
        "get"
      );
      if (res.data.code === 200) {
        if (res.data.status) {
          let fI = 0;
          let fj = 0;
          if (this.currentParams && this.currentParams.folder_oid) {
            fI = res.data.folders.findIndex(
              (item) => item.folder_oid == this.currentParams.folder_oid
            );
            fj = res.data.folders[fI].forms.findIndex(
              (item) => item.form_oid == this.currentParams.form_oid
            );
          }
          const _folder = res.data.folders[fI];
          if (_folder.forms) {
            const _form = _folder.forms[fj];
            this.formInfo = {
              folder_oid: _folder.folder_oid,
              folder_name: _folder.folder_name,
              ..._form,
            };
            if (this.$route.query.OCRURL && this.canOCR) {
              this.beforeOcrIdentify({
                folder_oid: _folder.folder_oid,
                folder_name: _folder.folder_name,
                ..._form,
              });
            } else {
               //首次加载初始化第一条数据
              this.getFormData({
                folder_oid: _folder.folder_oid,
                folder_name: _folder.folder_name,
                ..._form,
              });
            }
            this.visitList = res.data.folders;
          }
        }
      }
    },
    getFormData({
      folder_oid,
      form_oid,
      folder_form_id,
      subject_item_id,
      folder_name,
      form_name,
    }, isOcr = false, values = null) {
      this.currentParams = {
        project_id: this.projectId,
        subject_id: this.subject_id,
        folder_oid: folder_oid,
        form_oid: form_oid,
        folder_form_id: folder_form_id,
      };
      this.GetSubjectsForm(this.currentParams, subject_item_id, isOcr, values);
      this.folderName = folder_name;
      this.formName = form_name;
      this.checked = false;
      this.allField = [];
      this.allTempArr = [];
    },
    async GetSubjectsForm(obj, subject_item_id, isOcr = false, values = null) {
      let str = qs.stringify(obj);
      this.formList = [];
      this.formListData = [];
      this.form_info = null;
      if (!this.toast) {
        this.toast = Toast.loading("加载中...");
      }
      const res = await service.sendReq("get-subject-form?" + str, null, "get");
      if (res.data.code === 200) {
        if (res.data.status) {
          this.form_info = res.data.form_info;
          this.isLog = this.form_info.is_log;
          this.isLab = this.form_info.is_lab;
          if (isOcr) {
            res.data.form_values = [values];
          console.log(res.data);
          }
          const _data = handleEdcFormData(
            res.data,
            this.roleName,
            this.subjectReview,
            this.subjectSdv
          );
          if (this.isLog == 1 && this.isLab == 1) {
            this.logfields = res.data.fields;
            if (this.subject_item_id) {
              let i = _data.findIndex(
                (item) => item.subject_item_id == this.subject_item_id
              );
              this.currentLogId = i > -1 ? i : 0;
            }
            this.formList = _data[this.currentLogId].array;
            this.subject_item_id = _data[this.currentLogId].subject_item_id;
          } else
           if (this.isLog && this.isLab !== 1) {
            this.logfields = res.data.fields;
            if (this.subject_item_id) {
              let i = _data.findIndex(
                (item) => item.subject_item_id == this.subject_item_id
              );
              this.currentLogId = i > -1 ? i : 0;
            }
            this.formList.push(_data[this.currentLogId]);
            this.subject_item_id = _data[this.currentLogId].subject_item_id;
          } else {
            this.subject_item_id = subject_item_id;
            this.formList = _data;
          }
          this.is_temp_data = this.formList[0].is_temp_data;
          this.formListData = _data;
          this.page_logic = res.data.page_logic;
          if (this.page_logic.length && _data.length) {
            this.pageLogicFun();
          }
        }
      }
      this.toast.clear();
      this.toast = null;
    },
    onOpenVisitPopup() {
      this.showVisit = true;
    },
    onCloseVisitPopup() {
      this.showVisit = false;
    },
    /**
     * 访视展开
     */
    onVisitCollapseChange(event) {
      this.visitActives = event;
    },
    /**
     * 点击访视表单
     */
    onSelectForm(item, childItem) {
      const params = handleFormParams(item, childItem);
      if (params.disabled) {
        return;
      }
      this.currentLogId = 0;
      this.showVisit = false;
      this.formInfo = childItem;
      this.getFormData(params);
      this.checked = false;
      this.allField = [];
      this.allTempArr = [];
      this.$nextTick(() => {
        this.onCheckAll();
      });
    },
    onPhonoClick() {
      let str = "";
      if (this.currentParams) {
        str = "&" + qs.stringify(this.currentParams);
      }
      wx.miniProgram.navigateTo({
        url:
          "/packageEDC/pages/info/photo-page/index?subjectId=" +
          this.subject_id +
          str,
      });
    },
    pageLogicFun() {
      let obj = {};
      let page_logic = this.page_logic;
      if (page_logic.length == 0) {
        return;
      }
      let arr = [];
      this.formList.forEach((item) => {
        let o = {};
        item.childs.forEach((child) => {
          obj[child.key] = child.value;
          o[child.key] = child.value;
        });
        arr.push(o);
      });

      let reg2 = /\$([A-Za-z0-9_]+)/g;
      // let reg3 = /([A-Za-z0-9_]+)\$/g;
      let tempstr = page_logic;
      let str = "";
      arr.forEach((item) => {
        let x = this.handleString(item, tempstr);
        x = this.handleString2(item, x);
        str += x;
      });
      page_logic = str.replace(reg2, (match, p1) => {
        if (p1) {
          return "obj.$" + p1;
        }
      });
      let a;
      let subjectReview, subjectSdv;
      subjectReview = this.subjectReview;
      subjectSdv = this.subjectSdv;
      console.log(subjectSdv, subjectReview);
      eval(`a = function (obj, formList, vm) {
            let enable = ${enableFun}.bind(formList, obj, vm);
            let disable = ${disableFun}.bind(formList, obj, vm);
            let hide = ${hideFun}.bind(formList, obj, vm);
            let hid = ${hideFun}.bind(formList, obj, vm);
            if(subjectReview == 1 || subjectSdv == 1) {
              hide = function() {};
              hid = function() {};
            }
            let show = ${showFun}.bind(formList, obj, vm);
            let inArray = ${inArrayFun}.bind(formList, obj, vm);
            ${page_logic};
          };
        `);
      try {
        a(obj, this.formList, this);
      } catch (error) {
        console.log(error);
      }
    },
    handleString(obj, str) {
      let reg1 = /\$([A-Za-z0-9_]+)\$/g;
      if (reg1.test(str)) {
        let tempStr = "";
        let arr = str.match(reg1);
        arr = [...new Set(arr)];
        Object.keys(obj).forEach((key) => {
          arr.forEach((item) => {
            let tempItem = item.substring(0, item.length - 1);
            let itemStr = str;
            let isChange = false;
            if (key.startsWith(tempItem)) {
              isChange = true;
              itemStr = itemStr.replaceAll(item, "$" + key);
            }
            if (isChange) {
              tempStr += itemStr;
            }
          });
        });
        return tempStr !== "" ? tempStr : str;
      }
      return str;
    },
    handleString2(obj, str) {
      let reg1 = /([A-Za-z0-9_]+)\$/g;
      if (reg1.test(str)) {
        let tempStr = "";
        let arr = str.match(reg1);
        arr = [...new Set(arr)];
        let itemStr = str;
        Object.keys(obj).forEach((key) => {
          key = key.substring(1);
          arr.forEach((item) => {
            let tempItem = item.substring(0, item.length - 1);
            let isChange = false;
            if (key.startsWith(tempItem)) {
              isChange = true;
              itemStr = itemStr.replaceAll(item, key);
            }
            if (isChange) {
              tempStr += itemStr;
            }
          });
        });
        return tempStr !== "" ? tempStr : str;
      }
      return str;
    },
    onDataChange(data, index) {
      this.$set(this.formList[index], "childs", data);
      this.pageLogicFun();
    },
    onCheckAll() {
      let rhfromRefs = this.$refs["rh-from-ref"];
      rhfromRefs.forEach((item) => {
        item.checkAll();
      });
    },
    onIsCheckAllChange(value, list, index) {
      this.checked = value;
      this.allTempArr[index] = list;
      let res = this.flatter(this.allTempArr);
      let obj = {};
      res.forEach((item) => {
        let k = item.substring(1);
        obj[k] = 1;
      });
      this.notCheckedList.forEach((item) => {
        let k = item.substring(1);
        obj[k] = 0;
      });
      this.allField = obj;
    },
    onNotCheckedEvent(list) {
      this.notCheckedList = list;
    },
    flatter(arr) {
      while (arr.some((item) => Array.isArray(item))) {
        arr = [].concat(...arr);
      }
      return arr;
    },
    async saveSdv() {
      const res = await service.sendReq(
        "save-sdv",
        {
          project_id: this.projectId,
          env_id: this.envId,
          role_id: this.roleId,
          subject_id: this.subject_id,
          data: [
            {
              subject_item_id: this.subject_item_id,
              form_oid: this.currentParams.form_oid,
              folder_oid: this.currentParams.folder_oid,
              field: this.allField,
            },
          ],
        },
        "post"
      );
      if (res.data.code === 200) {
        let msg = res.data.msg;
        Toast(msg);
        setTimeout(() => {
          this.reloadFun();
        }, 300);
      } else {
        let msg = res.data.msg;
        if (Array.isArray(msg)) {
          Toast(msg[0]);
        } else if (typeof msg === "string") {
          Toast(msg);
        }
      }
    },
    async saveReview() {
      const res = await service.sendReq(
        "save-review",
        {
          project_id: this.projectId,
          env_id: this.envId,
          role_id: this.roleId,
          subject_id: this.subject_id,
          data: [
            {
              subject_item_id: this.subject_item_id,
              form_oid: this.currentParams.form_oid,
              folder_oid: this.currentParams.folder_oid,
              role_name: this.roleName,
              field: this.allField,
            },
          ],
        },
        "post"
      );
      if (res.data.code === 200) {
        let msg = res.data.msg;
        Toast(msg);
        setTimeout(() => {
          this.reloadFun();
        }, 300);
      } else {
        let msg = res.data.msg;
        if (Array.isArray(msg)) {
          Toast(msg[0]);
        } else if (typeof msg === "string") {
          Toast(msg);
        }
      }
    },
    onReviewClick() {
      let arr = Object.keys(this.allField);
      if (arr.length === 0) {
        Toast({
          message: "请先选择需要review的表格",
          position: "top",
        });
        return;
      }
      this.saveReview();
    },
    onSdvClick() {
      let arr = Object.keys(this.allField);
      if (arr.length === 0) {
        Toast({
          message: "请先选择需要sdv的表格",
          position: "top",
        });
        return;
      }
      this.saveSdv();
    },
    onCreatQueryClick(obj) {
      sessionStorage.setItem("queryItem", JSON.stringify(obj));
      this.canOCR = false;
      this.$router.push({
        path: "/creatQuery",
        query: {
          roleName: this.roleName,
          roleId: this.roleId,
          envId: this.envId,
          env_name: this.envName,
          subject_item_id: this.subject_item_id,
          ...this.currentParams,
        },
      });
    },
    onSubmit(type) {
      let obj = {};
      this.formList.forEach((item) => {
        item.childs.forEach((child) => {
          let key = child.key.substring(1);
          obj[key] = child.value;
        });
      });
      this.handleSaveSubjectForm(type, obj);
    },
    async handleSaveSubjectForm(form_type, form_data) {
      let toa = Toast.loading("提交中...");
      const res = await service.sendReq(
        "save-subject-form",
        {
          project_id: this.currentParams.project_id,
          env_id: this.envId,
          subject_id: this.subject_id,
          folder_oid: this.currentParams.folder_oid,
          form_oid: this.currentParams.form_oid,
          form_data: form_data,
          subject_item_id: this.subject_item_id,
          form_type: form_type,
          role_id: this.roleId,
          folder_form_id: this.currentParams.folder_form_id || "",
        },
        "post"
      );
      toa.clear();
      if (res.data.code === 200) {
        let msg = res.data.msg;
        Toast(msg);
        if (this.subject_item_id == "") {
          this.currentLogId = 0;
        }
        if (res.data.data && res.data.data.subjectItemId) {
          this.subject_item_id = res.data.data.subjectItemId;
        }
        setTimeout(() => {
          this.reloadFun();
        }, 300);
      } else {
        let msg = res.data.msg;
        Toast(msg);
      }
    },
    reloadFun() {
      this.GetVisitList();
    },
    onAddLogClick() {
      if (this.formListData.length) {
        let data = this.formListData[this.formListData.length - 1];
        if (data.subject_item_id == "") {
          Toast("请先保存上一条");
          return;
        }
        let copyData = log(this.logfields, null, [])[0];
        copyData.id = this.formListData.length;
        copyData.is_temp_data = 1;
        copyData.subject_item_id = "";
        copyData.isMyAdd = true;
        let childs = copyData.childs;
        childs.forEach((item) => {
          if (typeOf(item.value) == "array") {
            item.value = [];
          } else {
            item.value = "";
          }
          item.canQuery = false;
        });
        this.formListData.push(copyData);
        this.onGridItemClick(copyData, this.formListData.length - 1);
      }
    },
    onAddLogClick2() {
      if (this.formListData.length) {
        let data = this.formListData[this.formListData.length - 1];
        if (data.subject_item_id == "") {
          Toast("请先保存上一条");
          return;
        }
        let copyData = {};
        copyData.array = common(this.logfields, null, [{}]);
        copyData.id = this.formListData.length;
        copyData.is_temp_data = 1;
        copyData.subject_item_id = "";
        copyData.isMyAdd = true;
        copyData.array.forEach(item => {
          let childs = item.childs;
          childs.forEach((item) => {
            if (typeOf(item.value) == "array") {
              item.value = [];
            } else {
              item.value = "";
            }
            item.canQuery = false;
          });
        })
        this.formListData.push(copyData);
        this.onGridItemClick2(copyData, this.formListData.length - 1);
      }
    },
    onGridItemClick(item, index) {
      if (this.formListData[this.currentLogId].isMyAdd) {
        this.formListData.splice(this.currentLogId, 1);
      }
      this.currentLogId = index;
      this.checked = false;
      this.formList.splice(0, 1, item);
      this.subject_item_id = item.subject_item_id;
      this.is_temp_data = item.is_temp_data;
      this.pageLogicFun();
    },
    onGridItemClick2(item, index) {
      if (this.formListData[this.currentLogId].isMyAdd) {
        this.formListData.splice(this.currentLogId, 1);
      }
      this.currentLogId = index;
      this.checked = false;
      this.formList = [];
      this.formList.push(...item.array);
      this.subject_item_id = item.subject_item_id;
      this.is_temp_data = item.is_temp_data;
      this.pageLogicFun();
    },
    onQueryClick(item) {
      this.canOCR = false;
      this.$router.push({
        path: "/queryList",
        query: {
          envId: this.envId,
          subjectId: this.subject_id,
          folder_oid: this.currentParams.folder_oid,
          form_oid: this.currentParams.form_oid,
          projectId: this.projectId,
          field_oid: item.key.substring(1),
          queryClose: this.queryClose,
          queryAdd: this.queryAdd,
          queryReply: this.queryReply,
          roleId: this.roleId,
          envName: this.envName,
          subject_item_id: this.subject_item_id,
          active: item.hasQuery,
        },
      });
    },
    beforeOcrIdentify(obj) {
      if (this.$route.query.OCRURL) {
        let objStr = this.$route.query.OCRURL
        try {
          let ob = JSON.parse(decodeURIComponent(objStr));
          this.OCRURL = ob;
          if (this.canOCR) {
            this.ocrIdentify(this.OCRURL, obj)
          }
        } catch (error) {
          console.log(error);
        }
        
      }
    },
    async ocrIdentify(ob, obj) {
      let t = Toast.loading({message: 'OCR识别中...', duration: 0});
      const res = await service.sendReq('ocr-identify',ob, 'post');
      t.clear();
      if (res.data.code === 200) {
        this.setFormParams(res.data.folder_oid, res.data.form_oid, res.data.values)
      } else {
        setTimeout(() => {
          this.getFormData(obj);
        }, 500)
         Toast({
          message: res.data.message,
        });
        return;
      }
    },
    setFormParams(folder_oid,form_oid, values) {
      // console.log(folder_oid,form_oid);
      let item = this.visitList.find(item => item.folder_oid === folder_oid);
      if (item) {
        let form = item.forms.find(i => i.form_oid === form_oid);
         this.getFormData({
            folder_oid: item.folder_oid,
            folder_name: item.folder_name,
            ...form,
          }, true, values);
      }

    }
  },
};
</script>

<style scoped lang="scss">
@import "../style/index.scss";
.container {
  background-color: $bg-grey;
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-top: 50px;
}

.header-w {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  color: white;
  padding: 0 8px;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
}

.header-w .left {
  display: flex;
  align-items: center;
}
.header-w .right {
  display: flex;
  align-items: center;
}
.icon-photo-album {
  margin-right: 5px;
}

.header-w .left .text {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding-bottom: 2px;
  margin-left: 5px;
}

.visit-item {
  border-bottom: 1px solid $bg-grey;
  padding: 5px 0;
  margin-left: 15px;
  color: #313131;
  display: flex;
  align-items: center;
}

.visit-item-name {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.disabled {
  color: #a19f9f !important;
}

.has-data {
  color: $primary-color !important;
}

.lock-icon {
  width: 14px;
  height: 14px;
  // margin: 0 5px;
  margin-right: 5px;
}

.collapse-item .visit-item:last-child {
  border-bottom: none;
}

.collapse-item {
  padding: 0 15px !important;
}
.grid-box {
  padding: 15px 5px 5px;
  overflow-y: scroll;
  height: 100%;
}
.van-collapse-item__content {
  padding: 0 16px;
}
.form-box {
  width: 96%;
  margin: auto;
  overflow-y: scroll;
  margin-top: 10px;
  //   background-color: #f7f1f1;
  border-radius: 5px;
  //   padding: 5px;
  box-sizing: border-box;
}
.form-one {
  margin-bottom: 5px;
  border-radius: 5px;
  box-sizing: border-box;
  overflow: hidden;
}
.form-box .form-one:last-of-type {
  margin-bottom: 0;
}
.check-all-box {
  border: 1px solid #ccc;
  background-color: #fff;
  justify-content: center;
  width: 80px;
  align-items: center;
  float: right;
  margin: 6px;
  height: 32px;
  border-radius: 5px;
  z-index: 11;
  position: sticky;
  top: 50px;
}
// .submit-btn {
// //   width: 87%;
// //   margin: auto;
// }
.btn-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 10px;
}
.footer {
  height: 32px;
  width: 96%;
  margin: auto;
}
.add-log-form {
  border: 1px solid #ccc;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 5px;
}
.log-grid-item {
  ::v-deep .van-grid-item__content {
    padding: 2px;
    span {
      line-height: 32px;
    }
  }
}
.log-active {
  color: #26bcf9;
}
</style>