
export function enableFun(obj, vm, ...args) {
    let str = args[0];
    let arr;
    if (str) {
        arr = str && str.split(',').map((item) => {
            if (typeof item === 'string') {
                if (!isNaN(Number(item))) {
                    return Number(item)
                }
                return '$' + item;
            } else if (typeof item == 'number') {
                return item;
            }
        });
    } else {
        arr = [3];
    }
    let formList = this;
    let zindex = 0;
    formList.forEach((formitem, index) => {
        let array = formitem.childs;
        arr.forEach((key, arrIndex) => {
            for (let i = 0; i < array.length; i++) {
                if (arrIndex == 0) {
                    zindex++
                }
                // if (index > 0 && arrIndex == 0) {
                //     zindex += formList[index - 1].childs.length + i + 1;
                // } else if (index == 0) {
                //     zindex = i + 2;
                // }
                if (typeof key == 'number') {
                    if (zindex >= key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        if (objChild.oldValue) {
                            objChild.value = objChild.oldValue;
                            objChild.oldValue = '';
                        }
                        objChild.isDisable = false;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                } else {
                    if (array[i].key === key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        if (objChild.oldValue) {
                            objChild.value = objChild.oldValue;
                            objChild.oldValue = '';
                        }
                        objChild.isDisable = false;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                }
            }
        });
    });
}

export function disableFun(obj, vm, ...args) {
    let str = args[0];
    let arr;

    if (str) {
        arr = str && str.split(',').map((item) => {
            if (typeof item === 'string') {
                if (!isNaN(Number(item))) {
                    return Number(item)
                }
                return '$' + item;
            } else if (typeof item == 'number') {
                return item
            }
        });
    } else {
        arr = [3];
    }
    let formList = this;
    let zindex = 0;
    formList.forEach((formitem, index) => {
        let array = formitem.childs;
        arr.forEach((key, arrIndex) => {
            for (let i = 0; i < array.length; i++) {
                if (arrIndex == 0) {
                    zindex++;
                }
                // if (index > 0 && arrIndex == 0) {
                //     zindex += formList[index - 1].childs.length + i + 1;
                // } else if (index == 0) {
                //     zindex = i + 1;
                // }
                if (typeof key == 'number') {
                    if (zindex >= key) {
                        console.log(zindex, array[i].key);
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        let value1 = '';
                        let val = array[i].value;
                        if (Array.isArray(val)) {
                            value1 = [];
                        }
                        if (!objChild.isDisable) {
                            objChild.oldValue = objChild.value;
                        }
                        if (!objChild.isReadonly) {
                            objChild.value = value1;
                        }
                        objChild.isDisable = true;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                } else {
                    if (array[i].key === key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        let value1 = '';
                        let val = array[i].value;
                        if (Array.isArray(val)) {
                            value1 = [];
                        }
                        if (!objChild.isDisable) {
                            objChild.oldValue = objChild.value;
                        }
                        if (!objChild.isReadonly) {
                            objChild.value = value1;
                        }
                        objChild.isDisable = true;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                }
            }
        });
    });
}

export function hideFun(obj, vm, ...args) {
    let str = args[0];
    let arr;
    if (str) {
        arr = str && str.split(',').map((item) => {
            if (typeof item === 'string') {
                if (!isNaN(Number(item))) {
                    return Number(item)
                }
                return '$' + item;
            } else if (typeof item == 'number') {
                return item;
            }
        });
    } else {
        arr = [3];
    }
    let formList = this;
    let zindex = 0;
    formList.forEach((formitem, index) => {
        let array = formitem.childs;
        arr.forEach((key, arrIndex) => {
            for (let i = 0; i < array.length; i++) {
                if(arrIndex == 0) {
                    zindex++;
                }
                // if (index > 0 && arrIndex == 0) {
                //     zindex += formList[index - 1].childs.length + i + 1;
                // } else if (index == 0) {
                //     zindex = i + 2;
                // }
                if (typeof key == 'number') {
                    if (zindex >= key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        let value1 = '';
                        let val = array[i].value;
                        if (Array.isArray(val)) {
                            value1 = [];

                        }
                        if (objChild.isShow) {
                            objChild.oldValue = objChild.value;
                        }
                        if (!objChild.isReadonly) {
                            objChild.value = value1;
                        }
                        objChild.isShow = false;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                } else {
                    if (array[i].key === key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        let value1 = '';
                        let val = array[i].value;
                        if (Array.isArray(val)) {
                            value1 = [];
                        }
                        if (objChild.isShow) {
                            objChild.oldValue = objChild.value;
                        }
                        if (!objChild.isReadonly) {
                            objChild.value = value1;
                        }
                        objChild.isShow = false;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                }
            }
        });
    });
}

export function showFun(obj, vm, ...args) {
    let str = args[0];
    let arr;
    if (str) {
        arr = str && str.split(',').map((item) => {
            if (typeof item === 'string') {
                if (!isNaN(Number(item))) {
                    return Number(item)
                }
                return '$' + item;
            } else if (typeof item == 'number') {
                return item;
            }
        });
    } else {
        arr = [3];
    }
    let formList = this;
    let zindex = 0;
    formList.forEach((formitem, index) => {
        let array = formitem.childs;
        arr.forEach((key, arrIndex) => {
            for (let i = 0; i < array.length; i++) {
                if (arrIndex == 0) {
                    zindex++
                }
                // if (index > 0 && arrIndex == 0) {
                //     zindex += formList[index - 1].childs.length + i + 1;
                // } else if (index == 0) {
                //     zindex = i + 2;
                // }
                if (typeof key == 'number') {
                    if (zindex >= key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        if (objChild.oldValue) {
                            objChild.value = objChild.oldValue;
                            objChild.oldValue = '';
                        }
                        objChild.isShow = true;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                } else {
                    if (array[i].key === key) {
                        let objChild = {};
                        Object.keys(array[i]).forEach(ix => {
                            objChild[ix] = array[i][ix];
                        })
                        if (objChild.oldValue) {
                            objChild.value = objChild.oldValue;
                            objChild.oldValue = '';
                        }
                        objChild.isShow = true;
                        vm.$set(vm.formList[index].childs, i, objChild);
                    }
                }
            }
        });
    });
}

export function inArrayFun(obj, vm, ...args) {
    let a1 = args[0];
    let a2 = args[1];
    if (Array.isArray(a2)) {
        return a2.includes(a1);
    } else {
        return a1 == a2
    }
}